
.myTeam {
  .v-toolbar__content {
    padding: 4px 0;
  }
  .permission {
    width: 100px;
  }
  .table {
    width: calc(100% - 32px);
    margin: 0 auto;
    border-bottom: 1px solid #ddd;
    table {
      border: none;
      th {
        border: none;
        font-size: 14px !important;
      }
      td {
        border: none;
      }
    }
  }
  .pagination {
    width: 100%;
    height: 80px;
    position: relative;
    .pagination_con {
      position: absolute;
      right: 0;
      top: 20px;
    }
  }
  .goback {
    cursor: pointer;
  }
  .goback:hover {
    color: #536dfe !important;
  }
}
